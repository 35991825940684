body {
  /* background: transparent !important; */
  background-color: #030303 !important;
}

.reactWorld {
  width: 100%;
}

.rw {
    margin: 0;
    padding: 0;
    font-family: "Press Start 2P", cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #edbd64 !important; */
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .playButton {
    background-color: #FFEB3B;
    border: 4px solid #877c20;
    font-family: "Press Start 2P", cursive;
    font-size: 26px;
    margin-top: 4%;
    color: #877c20;
    height: 20%;
    width: 70%;
  }
  h1{
    font-family: "Press Start 2P", cursive;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .rw code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  #element::-webkit-scrollbar {
    display: none;
  }
  